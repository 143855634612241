import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import {
  AddUserToCompanyRequest,
  AddUserToCompanyResponse,
  CreateCompanyRequest,
  CreateCompanyResponse,
  GetCompanyRequest,
  GetCompanyResponse,
  GetCompanyUsersRequest,
  GetCompanyUsersResponse,
  GetMemberByIdRequest,
  GetMemberByIdResponse,
  GetMyCompaniesRequest,
  GetMyCompaniesResponse,
  PromoteUserRequest,
  RemoveUserFromCompanyRequest,
  SearchCompanyUsersRequest,
  SearchCompanyUsersResponse,
  UpdateCompanyRequest,
} from './companies.models';
import { ICompanyApi } from './ICompanyApi';

export class CompanyApi implements ICompanyApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly getMyCompanies = async (req: GetMyCompaniesRequest) => {
    const res = await this.httpClient.get<GetMyCompaniesResponse>(
      '/core/company/my',
      {
        isAuthorized: true,
        params: {
          admin: req.admin,
        },
      }
    );
    return res.items;
  };

  public readonly getCompany = async ({ companyId }: GetCompanyRequest) => {
    const res = await this.httpClient.get<GetCompanyResponse>(
      `/core/company/${companyId}`
    );

    return res.item;
  };

  public readonly editCompany = async ({
    companyId,
    ...body
  }: UpdateCompanyRequest) => {
    await this.httpClient.put<void>(`/core/company/${companyId}`, {
      isAuthorized: true,
      body,
    });
  };

  public readonly createCompany = async ({
    name,
    license,
  }: CreateCompanyRequest) => {
    const res = await this.httpClient.post<CreateCompanyResponse>(
      '/core/company',
      {
        isAuthorized: true,
        body: { name, license },
      }
    );
    return res.item;
  };

  public readonly getMemberById = async ({
    companyId,
    userId,
  }: GetMemberByIdRequest) => {
    const res = await this.httpClient.get<GetMemberByIdResponse>(
      `/core/company/${companyId}/member/${userId}`
    );
    return res;
  };

  public readonly getCompanyUsers = async ({
    companyId,
    limit = 10,
    next,
    previous,
  }: GetCompanyUsersRequest) => {
    const res = await this.httpClient.get<GetCompanyUsersResponse>(
      `/core/company/${companyId}/member`,
      {
        isAuthorized: true,
        params: {
          limit: `${limit}`,
          next,
          previous,
        },
      }
    );
    return res;
  };

  public readonly searchUsers = async ({
    companyId,
    query,
  }: SearchCompanyUsersRequest) => {
    const res = await this.httpClient.get<SearchCompanyUsersResponse>(
      `/core/company/${companyId}/member/search`,
      {
        isAuthorized: true,
        params: {
          query,
        },
      }
    );

    return res;
  };

  public readonly removeUserFromCompany = async ({
    companyId,
    userId,
    leavingReason,
    leavingExplanation,
  }: RemoveUserFromCompanyRequest) => {
    await this.httpClient.delete<void>(
      `/core/company/${companyId}/member/${userId}`,
      { isAuthorized: true, body: { leavingReason, leavingExplanation } }
    );
  };

  public readonly promoteUserToUser = async ({
    companyId,
    userId,
  }: PromoteUserRequest) => {
    await this.httpClient.delete<void>(
      `/core/company/${companyId}/member/admin/${userId}`
    );
  };

  public readonly promoteUserToAdmin = async ({
    companyId,
    userId,
  }: PromoteUserRequest) => {
    await this.httpClient.put<void>(
      `/core/company/${companyId}/member/admin/${userId}`
    );
  };

  public readonly addUserToCompany = async ({
    companyId,
    email,
    username,
    hiringDate,
    birthDate,
    gender,
  }: AddUserToCompanyRequest) => {
    return await this.httpClient.put<AddUserToCompanyResponse>(
      `/core/company/${companyId}/member`,
      { isAuthorized: true, body: { hiringDate, email, username, birthDate, gender } }
    );
  };
}
