import React, {
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
// import { FC } from '../types/general.types';

const UmmyOutsideClickHandler = OutsideClickHandler as any;

export interface PopoverMenuRef {
  closeMenu: () => void;
}

type PopoverMenuProps = {
  containerClassName?: string;
  buttonClassName?: string;
  menuClassName?: string;
  buttonLayout: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
};

const PopoverMenu = React.forwardRef<PopoverMenuRef, PopoverMenuProps>(
  (props, ref) => {
    const {
      menuClassName,
      buttonClassName,
      containerClassName,
      buttonLayout,
      children,
      disabled,
    } = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const buttonRef = useRef<HTMLButtonElement>(null);

    const openMenu = useCallback(() => {
      setIsMenuOpen(true);
    }, []);

    const closeMenu = useCallback(() => {
      setIsMenuOpen(false);
    }, []);

    // Expose closeMenu method to parent components
    useImperativeHandle(ref, () => ({
      closeMenu,
    }));

    return (
      <div className={`${containerClassName || ''}`}>
        <button
          ref={buttonRef}
          className={`bg-transparent border-0 ${buttonClassName}`}
          onClick={openMenu}
          disabled={disabled}
        >
          {buttonLayout}
        </button>
        {isMenuOpen && (
          <div
            className={`absolute shadow-md rounded-md bg-white z-50 ${menuClassName}`}
          >
            <UmmyOutsideClickHandler onOutsideClick={closeMenu}>
              {children}
            </UmmyOutsideClickHandler>
          </div>
        )}
      </div>
    );
  }
);

export default PopoverMenu;
